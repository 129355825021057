<template>
  <b-card-code title="جداول الدراسية" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن.."
            />
            <b-input-group-append>
              <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
              <b-button
                v-if="canEdit"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة مادة جديدة</span>
              </b-button>
              <export-excel style="color:white;padding-top:10px"
                  class   = "btn btn-default"
                  :data   = "items"
                  :fields = "exportFields"
                  worksheet = "My Worksheet"
                  name    = "filename.xls">
                  استخراج ملف اكسل
              </export-excel>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col v-if="role" md="4">
          <b-form-group>
            <v-select
              v-model="department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.departments"
              placeholder="اختر القسم"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.stages.length > 0" md="4">
          <b-form-group>
            <v-select
              v-model="stage_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.stages"
              placeholder="اختر المرحلة"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.groups.length > 0" md="4">
          <b-form-group>
            <v-select
              v-model="group_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.groups"
              placeholder="اختر الكروب"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="canEdit" v-show="search.lectures.length > 0" md="4">
          <b-form-group>
            <v-select
              v-model="lecture_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.lectures"
              placeholder="اختر المحاضرة"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="search.types.length > 0" md="4">
          <b-form-group>
            <v-select
              v-model="type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.types"
              placeholder="اختر نوع الدراسية"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <v-select
              v-model="day_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.days"
              placeholder="اختر يوم"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col v-if="canEdit" md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col v-if="canEdit" md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل مادة دراسية"
      @show="checkUpdateModel"
      @ok="uM"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col v-if="role" md="12">
            <b-form-group>
              <label for="basic-password">القسم</label>
              <v-select
                v-model="editform.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="stages.length > 0">
            <b-form-group>
              <label for="basic-password">المرحلة</label>
              <v-select
                v-model="editform.stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="stages"
                placeholder="اختر المرحلة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="groups.length > 0">
            <b-form-group>
              <label for="basic-password">الشعبة</label>
              <v-select
                v-model="editform.group_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="groups"
                placeholder="اختر الشعبة"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="lectures.length > 0">
            <b-form-group>
              <label for="basic-password">المادة الدراسية</label>
              <v-select
                v-model="editform.lecture_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="lectures"
                placeholder="اختر المادة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">نوع الدراسة</label>
              <v-select
                v-model="editform.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="types"
                placeholder="اختر نوع الدراسة"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" >
            <b-form-group>
              <label for="basic-password">القاعة الدراسية</label>
              <v-select
                v-model="editform.room_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="rooms"
                placeholder="اختر القاعة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">ايام الاسبوع</label>
              <v-select
                v-model="editform.day_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="days"
                placeholder="اختر يوم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Time" label-for="time">
              <cleave
                id="time"
                class="form-control"
                :raw="false"
                v-model="editform.time"
                :state="editform.time.length > 0"
                :options="options.time"
                placeholder="hh:mm"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة مادة جديدة"
      @show="checkModel"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation" style="margin-bottom:130px">
        <b-form-row>
          <b-col v-if="role" md="12">
            <b-form-group>
              <label for="basic-password">القسم</label>
              <v-select
                v-model="form.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="stages.length > 0">
            <b-form-group>
              <label for="basic-password">المرحلة</label>
              <v-select
                v-model="form.stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="stages"
                placeholder="اختر المرحلة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="lectures.length > 0">
            <b-form-group>
              <label for="basic-password">المادة الدراسية</label>
              <v-select
                v-model="form.lecture_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="lectures"
                placeholder="اختر المادة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="groups.length > 0">
            <b-form-group>
              <label for="basic-password">الشعبة</label>
              <v-select
                v-model="form.group_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="groups"
                placeholder="اختر الشعبة"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">نوع الدراسة</label>
              <v-select
                v-model="form.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="types"
                placeholder="اختر نوع الدراسة"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" >
            <b-form-group>
              <label for="basic-password">القاعة الدراسية</label>
              <v-select
                v-model="form.room_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="rooms"
                placeholder="اختر القاعة الدراسية"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">ايام الاسبوع</label>
              <v-select
                v-model="form.day_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="days"
                placeholder="اختر يوم"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Time" label-for="time">
              <cleave
                id="time"
                class="form-control"
                :raw="false"
                v-model="form.time"
                :state="form.time.length > 0"
                :options="options.time"
                placeholder="hh:mm"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="حذف مادة"
      @ok="dM"
      ok-title="حذف الحقل"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل " {{ this.editform.ar_name }} " ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,BProgress,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,
    BBadge,
    BFormRow,BProgress,
    BDropdown,
    Cleave,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      role: null,
      canDelete: null,
      canEdit: null,
      currentPage: 0,
      day_id: "",
      department_id: "",
      lecture_id: "",
      stage_id: "",
      type_id: "",
      group_id: "",
      title: "",
      filter: null,
      filterOn: [],
      departments: [],
        days: [],
        types: [],
        groups: [],
        lectures: [],
        stages: [],
      teachers: [],
      rooms: [],
      search:{
        departments: [],
        days: [],
        types: [],
        groups: [],
        lectures: [],
        stages: [],
      },
      fields: [
        {
          key: "day.ar_title",
          label: "ايام الاسبوع",
        },
        {
          key: "time",
          label: "الوقت",
        },
        {
          key: "department.ar_name",
          label: "القسم",
        },
        {
          key: "stage.ar_name",
          label: "المرحلة",
        },
        {
          key: "lecture.ar_name",
          label: "المحاضرة الدراسية",
        },
        {
          key: "type.ar_title",
          label: "نوع الدراسة",
        },
        {
          key: "group.title",
          label: "الشعبة",
        },
        {
          key: "room.ar",
          label: "القاعة الدراسية",
        },
        { key: "teacher.ar_name", label: "المحاضر " },
        { key: "action", label: "الاجراءات" },
      ],
      exportFields:{
          'ايام الاسبوع': 'day.ar_title',
            'الوقت': 'time',
            'القسم': 'department.ar_name',
            'المرحلة': 'stage.ar_name',
            'المحاضرة الدراسية': 'lecture.ar_name',
            'المحاضر': 'teacher.ar_name',
            'نوع الدراسة': 'type.ar_title',
            'الشعبة': 'group.title',
            'القاعة الدراسية': 'room.ar',
      },
      exportData:[
        {
          Day: "day.ar_title",
          Time: "time",
          Department: "department.ar_name",
          Stage: "stage.ar_name",
          Lecture: "lecture.ar_name",
          Lecturer: "teacher.ar_name",
          Type: 'type.ar_title',
          Group: 'group.title',
          Room: 'room.ar',
        },
      ],
      items: [],
      form: {
        time: "",
        day_id: "",
        stage_id: "",
        type_id: "",
        department_id: "",
        group_id: "",
        room_id:"",
        lecture_id: "",
      },
      editform: {
        time: "",
        day_id: "",
        stage_id: "",
        department_id: "",
        lecture_id: "",
        type_id: "",
        room_id:"",
        group_id: "",
        id: null,
      },
      selected: [],
      selected2: [],
      options: {
        time: {
          time: true,
          timePattern: ["h", "m"],
        },
      },
    };
  },
  watch: {
    department: function (val) {
      this.gS();
    },
    title: function (val) {
      this.gS();
    },
    "editform.department_id": function (val) {
      if (val != null && val != "") {
        this.gSM1();
      }
    },
    "form.department_id": function (val) {
      if (val != null && val != "") {
        this.gSM3();
      }
    },
    "editform.stage_id": function (val) {
      if (val != null && val != "") {
        this.gSM5(), this.gSM8();
      }
    },
    "form.stage_id": function (val) {
      if (val != null && val != "") {
        this.gSM4(), this.gSM7();
      }
    },
    "department_id": function (val) {
      if (val != null && val != "") {
        this.gSM9(),this.gS();
      }
    },
    "stage_id": function (val) {
      if (val != null && val != "") {
        this.gSM10(),this.gSM11(),this.gS();
      }
    },
    "group_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "lecture_id": function (val) {
      if (val != null && val != "") {
       this.gS();
      }
    },
    "type_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "day_id": function (val) {
      if (val != null && val != "") {
        this.gS();
      }
    },
    "form.type_id": function (val) {
      if (val != null && val != "") {
        this.gSM12();
      }
    },
    "editform.type_id": function (val) {
      if (val != null && val != "") {
        this.gSM13()
      }
    },

  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    if (localStorage.getItem("NisourRole") == "admin") {
      this.role = true;
    } else {
      this.role = false;
    }
    if (localStorage.getItem("NisourRole") == "admin"||localStorage.getItem("NisourRole") == "coordinator") {
      this.canEdit = true;
    } else {
      this.canEdit = false;
    }
    if (localStorage.getItem("NisourRole") == "admin") {
      this.canDelete = true;
    } else {
      this.canDelete = false;
      this.department_id = localStorage.getItem("NisourDepartmentId")
      this.gSM9()
    }
    this.ID(), this.gS(), this.gSM(), this.gSM2(), this.gSM6();
  },
  methods: {
    async gSM() {
      await this.axios
        .get(
          `since/deparment?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&select=true`
        )
        .then((res) => {
          this.departments = [];
          this.search.departments = [];
          this.departments = res.data.items;
          this.search.departments = res.data.items;
        })
    },
    async gSM1() {
      this.stages = [];
      this.editform.stage_id == "";
      if (isNaN(this.editform.department_id)) {
        this.editform.department_id = this.editform.department_id["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.editform.department_id}?select=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM2() {
      this.days = [];
      this.editform.days == "";
      await this.axios
        .get(`daies`)
        .then((res) => {
          this.days = [];
          this.days = res.data.items;
          this.search.days = res.data.items;
        })
    },
    async gSM3() {
      this.stages = [];
      this.form.stage_id == "";
      if (isNaN(this.form.department_id)) {
        this.form.department_id = this.form.department_id["id"];
      }
      await this.axios
        .get(`stage/clint/department/${this.form.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
    },
    async gSM4() {
      this.lectures = [];
      this.form.lecture_id == "";
      await this.axios
        .get(`lecture?select=true&stage=${this.form.stage_id.id}`)
        .then((res) => {
          this.lectures = [];
          this.lectures = res.data.items;
        })
    },
    async gSM5() {
      this.lectures = [];
      await this.axios
        .get(`lecture?select=true&stage=${this.editform.stage_id}`)
        .then((res) => {
          this.lectures = [];
          this.lectures = res.data.items;
        })
    },
    async gSM6() {
      this.types = [];
      await this.axios
        .get(`types`)
        .then((res) => {
          this.types = [];
          this.types = res.data.items;
          this.search.types = res.data.items;
        })
    },
    async gSM7() {
      this.groups = [];
      this.form.group_id == "";
      await this.axios
        .get(`groups?select=true&stage=${this.form.stage_id.id}`)
        .then((res) => {
          this.groups = [];
          this.groups = res.data.items;
        })
    },
    async gSM8() {
      this.groups = [];
      this.editform.group_id == "";
      await this.axios
        .get(`groups?select=true&stage=${this.editform.stage_id}`)
        .then((res) => {
          this.groups = [];
          this.groups = res.data.items;
        })
    },
    async gSM9() {
      this.search.stages = [];
      this.stage_id == "";
      if (isNaN(this.department_id)) {
        this.department_id = this.department_id['id'];
      }
      await this.axios
        .get(`stage/clint/department/${this.department_id}?select=true`)
        .then((res) => {
          this.search.stages = [];
          this.search.stages = res.data.items;
        })
    },
    async gSM10() {
      this.search.groups = [];
      this.group_id == "";
      await this.axios
        .get(`groups?select=true&stage=${this.stage_id.id}`)
        .then((res) => {
          this.search.groups = [];
          this.search.groups = res.data.items;
        })
    },
    async gSM11(){
      this.search.lectures = [];
      this.lecture_id == "";
      await this.axios
        .get(`lecture?select=true&stage=${this.stage_id.id}`)
        .then((res) => {
          this.search.lectures = [];
          this.search.lectures = res.data.items;
        })
    },
    async gSM12(){
      this.rooms = [];
      if (isNaN(this.form.type_id)) {
        this.form.type_id = this.form.type_id['id'];
      }
      await this.axios
        .get(`rooms/${this.form.type_id}`)
        .then((res) => {
          this.rooms = [];
          this.rooms = res.data.items;
        })
    },
    async gSM13(){
      this.rooms = [];
      if (isNaN(this.editform.type_id)) {
        this.editform.type_id = this.editform.type_id['id'];
      }
      await this.axios
        .get(`rooms/${this.editform.type_id}`)
        .then((res) => {
          this.rooms = [];
          this.rooms = res.data.items;
        })
    },
    checkModel() {
      this.teachers= [],
      this.groups= [],
      this.lectures= [],
      this.stages= [];
      if(localStorage.getItem("NisourRole")=="admin"){
      this.form.department_id=""
      }else{
      this.form.department_id = localStorage.getItem("NisourDepartmentId")
      this.department_id = localStorage.getItem("NisourDepartmentId")
      this.gSM3()
      }
      
    },
    checkUpdateModel() {
      this.teachers= [],
      this.groups= [],
      this.lectures= [],
      this.stages= [];
      if(localStorage.getItem("NisourRole")=="admin"){
      this.editform.department_id=""
      }else{
      this.editform.department_id = localStorage.getItem("NisourDepartmentId")
      this.department_id = localStorage.getItem("NisourDepartmentId")
      this.gSM1()
      }
      
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      if (isNaN(this.editform.department_id)) {
        this.editform.department_id = this.editform.department_id["id"];
      }
      if (isNaN(this.editform.stage_id)) {
        this.editform.stage_id = this.editform.stage_id["id"];
      }
      if (isNaN(this.editform.lecture_id)) {
        this.editform.lecture_id = this.editform.lecture_id["id"];
      }
      if (isNaN(this.editform.day_id)) {
        this.editform.day_id = this.editform.day_id["id"];
      }
      if (isNaN(this.editform.type_id)) {
        this.editform.type_id = this.editform.type_id["id"];
      }
      if (isNaN(this.editform.room_id)) {
        this.editform.room_id = this.editform.room_id["id"];
      }
      if (isNaN(this.editform.group_id)) {
        this.editform.group_id = this.editform.group_id["id"];
      }
      await this.axios
        .post(`tables/${this.editform.id}`, this.editform,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
            this.gS();
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
        this.gSM9()
    },
    async aNM() {
      if (isNaN(this.form.department_id)) {
        this.form.department_id = this.form.department_id["id"];
      }
      if (isNaN(this.form.stage_id)) {
        this.form.stage_id = this.form.stage_id["id"];
      }
      if (isNaN(this.form.lecture_id)) {
        this.form.lecture_id = this.form.lecture_id["id"];
      }
      if (isNaN(this.form.day_id)) {
        this.form.day_id = this.form.day_id["id"];
      }
      if (isNaN(this.form.type_id)) {
        this.form.type_id = this.form.type_id["id"];
      }
      if (isNaN(this.form.room_id)) {
        this.form.room_id = this.form.room_id["id"];
      }
      if (isNaN(this.form.group_id)) {
        this.form.group_id = this.form.group_id["id"];
      }
      await this.axios
        .post(`tables`, this.form,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
            this.gS();
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
      this.gSM9()
    },
    rF() {
      (this.form.time = ""),
        (this.form.day_id = ""),
        (this.form.stage_id = ""),
        (this.form.lecture_id = ""),
        (this.form.department_id = "");
      this.form.type_id = "";
      this.form.room_id = "";
      this.form.group_id = "";
    },
    rEF() {
      (this.editform.time = ""),
        (this.editform.day_id = ""),
        (this.editform.stage_id = ""),
        (this.editform.lecture_id = ""),
        (this.editform.department_id = "");
      this.editform.type_id = "";
      this.editform.group_id = "";
      this.editform.room_id = "";
      this.editform.id = "";
    },
    async dM() {
      // console.log(this.$router.currentRoute.name)
      await this.axios
        .delete(`tables/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.department_id = item.department_id;
      this.editform.stage_id = item.stage_id;
      this.editform.lecture_id = item.lecture_id;
      this.editform.day_id = item.day_id;
      this.editform.time = item.time;
      this.editform.type_id = item.type_id;
      this.editform.group_id = item.group_id;
      this.editform.room_id = item.room_id;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.ar_name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      // if (isNaN(this.department) & (this.department != "")) {
      //   this.department = this.department["id"];
      // }
      await this.axios
        .get(
          `tables?take=${this.perPage}&skip=${this.currentPage - 1}&title=${
            this.title
          }&department=${this.department_id}&stage_id=${this.stage_id.id}&group_id=${this.group_id.id}
          &lecture_id=${this.lecture_id.id}&type_id=${this.type_id.id}&day_id=${this.day_id.id}&teacher_id=${this.teacher_id}` 
        )
        .then((res) => {
          console.log(res)
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(`tables?take=${this.perPage}&skip=${this.currentPage - 1}`) //&teacher_id=${this.teacher_id}
        .then((res) => {
          console.log(res);
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""), (this.department = "");
      this.day_id = "",
      this.lecture_id= "",
      this.stage_id= "",
      this.type_id="",
      this.group_id="",
      this.gS();
    },
  },
};
</script>
